function ShipStation() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlLang="http://www.serif.com/"
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g transform="matrix(1,0,0,1,-4.90691,-4.74379)">
        <g>
          <g transform="matrix(-1,0,0,1,99.0139,-239.568)">
            <rect
              x="78.128"
              y="244.312"
              width="15.979"
              height="7.991"
              style={{ fill: "rgb(48,105,177)" }}
            />
          </g>
          <g transform="matrix(-1,0,0,1,91.0259,-223.573)">
            <rect
              x="78.128"
              y="236.315"
              width="7.991"
              height="7.99"
              style={{ fill: "rgb(255,192,50)" }}
            />
          </g>
          <g transform="matrix(-1,0,0,1,107.005,-223.573)">
            <rect
              x="86.119"
              y="236.315"
              width="7.988"
              height="7.99"
              style={{ fill: "rgb(144,196,233)" }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ShipStation;
