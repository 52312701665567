export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_DESKERA_INFO = "SET_DESKERA_INFO";
export const SET_USER_INFO = "SET_USER_INFO";

export const CREATE_TOAST = "CREATE_TOAST";
export const DELETE_TOAST = "DELETE_TOAST";
export const MARK_TOAST_DELETED = "MARK_TOAST_DELETED";

export const TALLY_SET_CONFIG = "TALLY_SET_CONFIG";
export const TALLY_FAILED_CONFIG_LOAD = "TALLY_FAILED_CONFIG_LOAD";
export const TALLY_SET_ACCOUNTS = "TALLY_SET_ACCOUNTS";
export const TALLY_FAILED_ACCOUNT_LOAD = "TALLY_FAILED_ACCOUNT_LOAD";
export const TALLY_SET_CREDIT_NOTES = "TALLY_SET_CREDIT_NOTES";
export const TALLY_FAILED_CREDIT_NOTES_LOAD = "TALLY_FAILED_CREDIT_NOTES_LOAD";
export const TALLY_SET_DEBIT_NOTES = "TALLY_SET_DEBIT_NOTES";
export const TALLY_FAILED_DEBIT_NOTES_LOAD = "TALLY_FAILED_DEBIT_NOTES_LOAD";
export const TALLY_SET_JOURNAL_VOUCHERS = "TALLY_SET_JOURNAL_VOUCHERS";
export const TALLY_FAILED_JOURNAL_VOUCHERS_LOAD =
  "TALLY_FAILED_JOURNAL_VOUCHERS_LOAD";
export const TALLY_SET_PRODUCTS = "TALLY_SET_PRODUCTS";
export const TALLY_FAILED_PRODUCTS_LOAD = "TALLY_FAILED_PRODUCTS_LOAD";
export const TALLY_SET_CUSTOMERS = "TALLY_SET_CUSTOMERS";
export const TALLY_FAILED_CUSTOMERS_LOAD = "TALLY_FAILED_CUSTOMERS_LOAD";
export const TALLY_SET_INVOICES = "TALLY_SET_INVOICES";
export const TALLY_FAILED_INVOICES_LOAD = "TALLY_FAILED_INVOICES_LOAD";
export const TALLY_SET_BILLS = "TALLY_SET_BILLS";
export const TALLY_FAILED_BILLS_LOAD = "TALLY_FAILED_BILLS_LOAD";
export const TALLY_SET_PAYMENTS = "TALLY_SET_PAYMENTS";
export const TALLY_FAILED_PAYMENTS_LOAD = "TALLY_FAILED_PAYMENTS_LOAD";
export const TALLY_SET_RECEIPTS = "TALLY_SET_RECEIPTS";
export const TALLY_FAILED_RECEIPTS_LOAD = "TALLY_FAILED_RECEIPTS_LOAD";
export const TALLY_SET_PURCHASE_ORDERS = "TALLY_SET_PURCHASE_ORDER";
export const TALLY_FAILED_PURCHASE_ORDERS = "TALLY_FAILED_PURCHASE_ORDER";
export const TALLY_SET_SALES_ORDERS = "TALLY_SET_SALES_ORDER";
export const TALLY_FAILED_SALES_ORDER = "TALLY_FAILED_SALES_ORDER";
export const TALLY_SET_GOODS_RECEIPT_NOTES = "TALLY_SET_GOODS_RECEIPT_NOTES";
export const TALLY_FAILED_GOODS_RECEIPT_NOTES_LOAD =
  "TALLY_FAILED_GOODS_RECEIPT_NOTES_LOAD";
export const TALLY_SET_DELIVERY_NOTES = "TALLY_SET_DELIVERY_NOTES";
export const TALLY_FAILED_DELIVERY_NOTES_LOAD =
  "TALLY_FAILED_DELIVERY_NOTES_LOAD";
