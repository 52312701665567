import {
  getTallyConfig,
  getTallyConnection,
  getTallyCreditNotes,
  getTallyDebitNotes,
  getTallyJournalVoucher,
  getTallyProducts,
  getTallyCustomers,
  getTallyInvoices,
  getTallyBills,
  getTallyPayments,
  getTallyReceipts,
  getTallyPurchaseOrders,
  getTallySalesOrders,
  getTallyGRN,
  getTallyDeliveryNotes,
} from "../../api";
import {
  TALLY_SET_CONFIG,
  TALLY_FAILED_CONFIG_LOAD,
  TALLY_SET_ACCOUNTS,
  TALLY_FAILED_ACCOUNT_LOAD,
  TALLY_SET_CREDIT_NOTES,
  TALLY_FAILED_CREDIT_NOTES_LOAD,
  TALLY_SET_DEBIT_NOTES,
  TALLY_FAILED_DEBIT_NOTES_LOAD,
  TALLY_SET_JOURNAL_VOUCHERS,
  TALLY_FAILED_JOURNAL_VOUCHERS_LOAD,
  TALLY_SET_PRODUCTS,
  TALLY_FAILED_PRODUCTS_LOAD,
  TALLY_SET_CUSTOMERS,
  TALLY_FAILED_CUSTOMERS_LOAD,
  TALLY_SET_INVOICES,
  TALLY_FAILED_INVOICES_LOAD,
  TALLY_SET_BILLS,
  TALLY_FAILED_BILLS_LOAD,
  TALLY_FAILED_PAYMENTS_LOAD,
  TALLY_SET_PAYMENTS,
  TALLY_FAILED_RECEIPTS_LOAD,
  TALLY_SET_RECEIPTS,
  TALLY_SET_PURCHASE_ORDERS,
  TALLY_FAILED_PURCHASE_ORDERS,
  TALLY_FAILED_SALES_ORDER,
  TALLY_SET_SALES_ORDERS,
  TALLY_SET_GOODS_RECEIPT_NOTES,
  TALLY_FAILED_GOODS_RECEIPT_NOTES_LOAD,
  TALLY_FAILED_DELIVERY_NOTES_LOAD,
  TALLY_SET_DELIVERY_NOTES,
} from "../types";

export function loadConfig() {
  return async function (dispatch, getState) {
    try {
      const config = await getTallyConfig();
      dispatch(setConfig(config));
    } catch (err) {
      dispatch(failedConfigLoad());
    }
  };
}

export function setConfig(config) {
  return {
    type: TALLY_SET_CONFIG,
    config: config,
  };
}

export function failedConfigLoad() {
  return {
    type: TALLY_FAILED_CONFIG_LOAD,
  };
}

export function loadAccounts() {
  return async function (dispatch, getState) {
    const accountLoading = getState().tally.accounts.loading;

    try {
      const accounts = await getTallyConnection();
      dispatch(setAccounts(accounts));
    } catch (err) {
      dispatch(failedAccountLoad());
    }
  };
}

export function setAccounts(accounts) {
  return {
    type: TALLY_SET_ACCOUNTS,
    accounts: accounts,
  };
}

export function failedAccountLoad() {
  return {
    type: TALLY_FAILED_ACCOUNT_LOAD,
  };
}

export function loadCreditNotes(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const creditNotes = await getTallyCreditNotes(
        limit,
        page,
        status,
        sortBy,
        search ?? ""
      );
      dispatch(setCreditNotes(creditNotes));
    } catch (err) {
      dispatch(failedCreditNotesLoad());
    }
  };
}

export function setCreditNotes(notesData) {
  return {
    type: TALLY_SET_CREDIT_NOTES,
    creditNotes: notesData,
  };
}

export function failedCreditNotesLoad() {
  return {
    type: TALLY_FAILED_CREDIT_NOTES_LOAD,
  };
}

export function loadDebitNotes(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const debitNotes = await getTallyDebitNotes(
        limit,
        page,
        status,
        sortBy,
        search ?? ""
      );
      dispatch(setDebitNotes(debitNotes));
    } catch (err) {
      dispatch(failedDebitNotesLoad());
    }
  };
}

export function setDebitNotes(notesData) {
  return {
    type: TALLY_SET_DEBIT_NOTES,
    debitNotes: notesData,
  };
}

export function failedDebitNotesLoad() {
  return {
    type: TALLY_FAILED_DEBIT_NOTES_LOAD,
  };
}

export function loadJournalVoucher(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const journalVouchers = await getTallyJournalVoucher(
        limit,
        page,
        status,
        sortBy,
        search ?? ""
      );
      dispatch(setJournalVouchers(journalVouchers));
    } catch (err) {
      dispatch(failedJournalVouchersLoad());
    }
  };
}

export function setJournalVouchers(journalData) {
  return {
    type: TALLY_SET_JOURNAL_VOUCHERS,
    journalVouchers: journalData,
  };
}

export function failedJournalVouchersLoad() {
  return {
    type: TALLY_FAILED_JOURNAL_VOUCHERS_LOAD,
  };
}

export function loadProducts(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const tallyProducts = await getTallyProducts(
        limit,
        page,
        status,
        sortBy,
        search ?? ""
      );
      dispatch(setProducts(tallyProducts));
    } catch (err) {
      dispatch(failedProductsLoad());
    }
  };
}

export function setProducts(productsData) {
  return {
    type: TALLY_SET_PRODUCTS,
    tallyProducts: productsData,
  };
}

export function failedProductsLoad() {
  return {
    type: TALLY_FAILED_PRODUCTS_LOAD,
  };
}

export function loadCustomers(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const tallyCustomers = await getTallyCustomers(
        limit,
        page,
        status,
        sortBy,
        search ?? ""
      );
      dispatch(setCustomers(tallyCustomers));
    } catch (err) {
      dispatch(failedCustomerLoad());
    }
  };
}

export function setCustomers(customersData) {
  return {
    type: TALLY_SET_CUSTOMERS,
    tallyCustomers: customersData,
  };
}

export function failedCustomerLoad() {
  return {
    type: TALLY_FAILED_CUSTOMERS_LOAD,
  };
}

export function loadInvoicesVoucher(
  limit,
  page,
  status,
  sortBy,
  search,
  fromDate,
  toDate
) {
  return async function (dispatch, getState) {
    try {
      const invoicesVouchers = await getTallyInvoices(
        limit,
        page,
        status,
        sortBy,
        search ?? "",
        fromDate ?? "",
        toDate ?? ""
      );
      dispatch(setInvoicesVouchers(invoicesVouchers));
    } catch (err) {
      dispatch(failedInvoicesVouchersLoad());
    }
  };
}

export function setInvoicesVouchers(invoicesVouchers) {
  return {
    type: TALLY_SET_INVOICES,
    invoices: invoicesVouchers,
  };
}

export function failedInvoicesVouchersLoad() {
  return {
    type: TALLY_FAILED_INVOICES_LOAD,
  };
}

export function loadBillsVoucher(
  limit,
  page,
  status,
  sortBy,
  fromDate,
  toDate,
  search
) {
  return async function (dispatch, getState) {
    try {
      const billsVouchers = await getTallyBills(
        limit,
        page,
        status,
        sortBy,
        fromDate ?? "",
        toDate ?? "",
        search ?? ""
      );
      dispatch(setBillsVouchers(billsVouchers));
    } catch (err) {
      dispatch(failedBillsVouchersLoad());
    }
  };
}

export function setBillsVouchers(billsVouchers) {
  return {
    type: TALLY_SET_BILLS,
    bills: billsVouchers,
  };
}

export function failedBillsVouchersLoad() {
  return {
    type: TALLY_FAILED_BILLS_LOAD,
  };
}

export function loadPayments(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const paymentsData = await getTallyPayments(
        limit,
        page,
        status,
        sortBy,
        search ?? ""
      );
      dispatch(setPayments(paymentsData));
    } catch (err) {
      dispatch(failedPaymentsLoad());
    }
  };
}

export function setPayments(paymentsData) {
  return {
    type: TALLY_SET_PAYMENTS,
    payments: paymentsData,
  };
}

export function failedPaymentsLoad() {
  return {
    type: TALLY_FAILED_PAYMENTS_LOAD,
  };
}

export function loadReceipts(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const receiptsData = await getTallyReceipts(
        limit,
        page,
        status,
        sortBy,
        search ?? ""
      );
      dispatch(setReceipts(receiptsData));
    } catch (err) {
      dispatch(failedReceiptsLoad());
    }
  };
}

export function setReceipts(receiptsData) {
  return {
    type: TALLY_SET_RECEIPTS,
    receipts: receiptsData,
  };
}

export function failedReceiptsLoad() {
  return {
    type: TALLY_FAILED_RECEIPTS_LOAD,
  };
}

export function loadPurchaseOrder(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const poData = await getTallyPurchaseOrders(
        limit,
        page,
        status,
        sortBy,
        search ?? ""
      );
      dispatch(setPurchaseOrder(poData));
    } catch (err) {
      dispatch(failedPaymentsLoad());
    }
  };
}

export function setPurchaseOrder(posData) {
  return {
    type: TALLY_SET_PURCHASE_ORDERS,
    purchaseOrders: posData,
  };
}

export function failedPurchaseOrderLoad() {
  return {
    type: TALLY_FAILED_PURCHASE_ORDERS,
  };
}

export function loadSalesOrders(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const soData = await getTallySalesOrders(
        limit,
        page,
        status,
        sortBy,
        search ?? ""
      );
      dispatch(setSalesOrders(soData));
    } catch (err) {
      dispatch(failedSalesOrderLoad());
    }
  };
}

export function setSalesOrders(soData) {
  return {
    type: TALLY_SET_SALES_ORDERS,
    salesOrders: soData,
  };
}

export function failedSalesOrderLoad() {
  return {
    type: TALLY_FAILED_SALES_ORDER,
  };
}

export function loadGrn(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const grn = await getTallyGRN(
        limit,
        page,
        status,
        sortBy,
        search ?? ""
      );
      dispatch(setGrn(grn));
    } catch (err) {
      dispatch(failedGrnLoad());
    }
  };
}

export function setGrn(notesData) {
  return {
    type: TALLY_SET_GOODS_RECEIPT_NOTES,
    grnData: notesData,
  };
}

export function failedGrnLoad() {
  return {
    type: TALLY_FAILED_GOODS_RECEIPT_NOTES_LOAD,
  };
}

export function loadDeliveryNotes(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const deliveryNotes = await getTallyDeliveryNotes(
        limit,
        page,
        status,
        sortBy,
        search ?? ""
      );
      dispatch(setDeliveryNotes(deliveryNotes));
    } catch (err) {
      dispatch(failedDeliveryNotesLoad());
    }
  };
}

export function setDeliveryNotes(notesData) {
  return {
    type: TALLY_SET_DELIVERY_NOTES,
    deliveryNotes: notesData,
  };
}

export function failedDeliveryNotesLoad() {
  return {
    type: TALLY_FAILED_DELIVERY_NOTES_LOAD,
  };
}
