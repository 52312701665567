import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../components/Alert";
import { Link } from "react-router-dom";
import { loadAccounts, loadCustomers } from "../../redux/actions/tally";
import ServerPaginationTable from "../../components/ServerPaginationTable";
import SyncStatus from "../../components/SyncStatus";
import {
  migrateTallyCustomersData,
  migrateCustomersToTally,
} from "../../api/index";
import SyncRemarkRender from "../../components/SyncRemarkRender";

const columns = [
  {
    Header: "Name",
    accessor: "customerName",
  },
  {
    Header: "Customer Id",
    accessor: "cartCustomerId",
  },
  {
    Header: "Deskera Id",
    accessor: "deskeraId",
  },
  {
    Header: "Deskera Contact Code",
    accessor: "deskeraDocumentCode",
  },
  {
    Header: "Sync Status",
    accessor: "syncStatus",
    Cell: ({ cell: { row } }) => {
      return (
        <SyncStatus
          status={row.original.syncStatus}
          reason={row.original.reason}
          id={row.original.cartCustomerId}
        />
      );
    },
  },
  {
    Header: "Sync Remarks",
    accessor: "reason",
    disableSortBy: true,
    Cell: ({ cell: { row } }) => {
      let value =
        row.original?.syncStatus !== "SUCCESSFUL" && row.original?.reason
          ? row.original?.reason
          : "";
      return <SyncRemarkRender value={value} id={row.original.id.toString()} />;
    },
  },
];

function Customers() {
  const tallyConfig = useSelector((state) => state.tally.accounts);
  const customersData = useSelector((state) => {
    return state.tally.tallyCustomers;
  });
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(loadAccounts());
    dispatch(loadCustomers());
  }, []);

  const data = useMemo(() => customersData.data, [customersData]);

  useEffect(() => {
    setLoading(false);
    if (customersData && customersData?.data?.totalPages) {
      setPageCount(customersData?.data?.totalPages);
    }
  }, [customersData]);

  const refreshData = ({ pageSize, pageIndex, status, sortBy, search }) => {
    setLoading(true);
    dispatch(loadCustomers(pageSize, pageIndex, status, sortBy, search ?? ""));
  };

  return (
    <>
      <div className='p-4'>
        <h3 className='mb-3'>Customers</h3>
        {(!tallyConfig.data || !tallyConfig.data.syncEnable) && (
          <Alert type='warning'>
            Your synced Customers will appear here. Please complete{" "}
            <Link to='/app/tally' className='text-alert-warning'>
              <u>Setup</u>
            </Link>{" "}
            before continuing.
          </Alert>
        )}
        <ServerPaginationTable
          data={data.content ? data.content : []}
          columns={columns}
          cart='tally'
          refresh={refreshData}
          migrate={migrateTallyCustomersData}
          syncToTally={migrateCustomersToTally}
          tab='customers'
          pageCount={pageCount}
          loading={loading}
          rowId='id'
        />
      </div>
    </>
  );
}

export default Customers;
