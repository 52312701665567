import clsx from "clsx";

function Alert({ type, className, children }) {
  const styles = {
    warning: ["alert-warning", "border-warning"],
    danger: ["alert-danger", "border-danger"],
    success: ["alert-success", "border-success"],
    primary: ["alert-primary", "border-primary"],
  };

  return (
    <div
      className={clsx("dk-alert border-bottom", ...styles[type], className)}
      role='alert'
      style={{ padding: 10, marginBottom: 10 }}
    >
      {children}
    </div>
  );
}

export default Alert;
