import React, { useEffect, useRef, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import ic_calendar from "../assets/ic_calendar.png";
import clsx from "clsx";
import UpArrowIcon from "../assets/UpArrow";
import ForwardRight from "../assets/ForwardRight";
import ArrowRight from "../assets/ArrowRight";
import Select from "react-select";
import customSelectTheme from "../utils/selectTheme";
import EmptyTray from "../assets/EmptyTray";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../redux/actions/toasts";
import ArrowClockwise from "../assets/ArrowClockwise";
import { getDeskeraTenantDetails } from "../api";
import Moment from "moment";
import { startDateFormat } from "../utils/Constants";
import {
  DKDateRangePicker,
  showAlert,
  getDateAsString,
} from "deskera-ui-library";
import { Checkbox } from "./Checkbox";
import SearchFilter from "./Filters/SearchFilter";
import SearchBar from "./Filters/SearchBar";
import { Utility } from "../utils/Utility";
function ServerPaginationTable({
  columns,
  data,
  migrate,
  syncToTally,
  cart,
  refresh,
  minHeight,
  tab,
  pageCount: controlledPageCount,
  loading,
  rowId,
}) {
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState([]);
  const [hover, setHover] = useState(false);
  const [triggered, setTriggered] = useState(false);
  const [triggeredTallySyncBtn, setTriggeredTallySyncBtn] = useState(false);
  const syncTrigger = useRef("");
  const dispatch = useDispatch();
  const tenantInfo = useSelector((state) => state.auth.user);
  const [loadConfigOpen, setLoadConfigOpen] = useState(false);
  const [filterDates, setFilterDates] = useState(undefined);
  const [showHideCalendar, setShowHideCalendar] = useState(false);
  const [syncBigCommerceOrdersConfigOpen, setSyncBigCommerceOrdersConfigOpen] =
    useState(false);
  const [prevPageIndex, setPrevPageIndex] = useState(0);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
      manualSortBy: true,
      autoResetSelectedRows: false,
      getRowId: (row) => row[rowId],
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",

          Header: ({
            getToggleAllPageRowsSelectedProps,
            toggleRowSelected,
            rows,
            selectedFlatRows,
          }) => {
            const { onChange, style, ...otherProps } =
              getToggleAllPageRowsSelectedProps();
            const getSelectableRows = rows.filter(
              (row) =>
                row.original.syncStatus !== "SUCCESSFUL" &&
                row.original.syncStatus !== "FULFILLED"
            );

            // Override onChange
            const onChangeOverride = (event) => {
              getSelectableRows.forEach((row) =>
                toggleRowSelected(row.id, event.currentTarget.checked)
              );
            };

            const checked =
              getSelectableRows.length == selectedFlatRows.length &&
              getSelectableRows.length > 0;
            const indeterminate = selectedFlatRows.length > 0 && !checked;
            const disabled = getSelectableRows.length == 0;
            const newProps = {
              onChange: onChangeOverride,
              style: style,
              checked: checked,
              indeterminate: indeterminate,
              disabled: disabled,
            };
            return <Checkbox {...newProps} />;
          },

          Cell: ({ row }) => (
            <div>
              <Checkbox
                {...row.getToggleRowSelectedProps()}
                disabled={
                  row.original.syncStatus === "SUCCESSFUL" ||
                  row.original.syncStatus === "FULFILLED"
                }
              />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
  const [initailRefreshOptions] = useState({
    pageIndex,
    pageSize,
    sortBy,
    selectedRowIds,
    status,
  });
  const colummsLength = columns.length;
  const dataLength = data.length;

  const pageSizeOptions = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
  ];

  const tallyFilterStatusOption = [
    {
      value: "SUCCESSFUL",
      label: "Successful",
    },
    {
      value: "FAILED",
      label: "Failed",
    },
    {
      value: "PENDING",
      label: "Pending",
    },
    {
      value: "PARTIAL",
      label: "Partial",
    },
  ];

  useEffect(() => {
    if (tab === "Bills" || tab === "Invoices") {
      const financialDates = getFinancialStartEndDate();
      setFilterDates({
        startDate: financialDates.startDate,
        endDate: financialDates.endDate,
      });
    }
  }, []);

  useEffect(() => {
    if (search != null) {
      refresh({ pageIndex: 0, status, pageSize, sortBy, search });
      gotoPage(0);
    }
  }, [search]);

  useEffect(() => {
    if (
      pageIndex !== prevPageIndex ||
      pageIndex !== initailRefreshOptions.pageIndex ||
      pageSize !== initailRefreshOptions.pageSize ||
      sortBy !== initailRefreshOptions.sortBy ||
      selectedRowIds !== initailRefreshOptions.selectedRowIds
    ) {
      refresh({ pageIndex, status, pageSize, sortBy, search });

      setPrevPageIndex(pageIndex);
    }
  }, [pageIndex, pageSize, sortBy]);

  // update pageIndex to 0 whenever status updated
  useEffect(() => {
    if (initailRefreshOptions.status !== status) {
      refresh({ pageIndex: 0, pageSize, status, search });
      gotoPage(0);
    }
  }, [status]);

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: "70%",
    }),
  };

  const updateSelectedStatus = (options) => {
    var selected = [];
    options.forEach((element) => {
      selected.push(element.value);
    });
    setStatus(selected);
  };

  const onFilter = (query) => {
    const search = encodeURIComponent(query["search"] ?? "");
    setSearch(search);
  };

  async function handleSyncSubmit(syncType = "") {
    setSyncBigCommerceOrdersConfigOpen(false);

    if (syncTrigger.current === "Deskera") {
      setTriggered(true);
    } else {
      setTriggeredTallySyncBtn(true);
    }

    var startDate;
    try {
      const tenantDetails = await getDeskeraTenantDetails();
      startDate = tenantDetails.bookBeginningStartDate;
    } catch (err) {
      if (syncTrigger.current === "Deskera") {
        setTriggered(true);
      } else {
        setTriggeredTallySyncBtn(true);
      }
      return;
    }

    try {
      const ids = Object.entries(selectedRowIds).map((k) => k[0]);
      let body = { startDate: Moment(startDate).format(startDateFormat) };
      body = { ...body, tallyIds: ids };

      if (syncTrigger.current === "Deskera") {
        await migrate(body);
      } else {
        await syncToTally(body);
      }

      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Data Sync Successfully",
        })
      );
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.response.data.message,
        })
      );
    }

    refresh({ pageIndex, pageSize, status });

    setTriggered(false);
    setTriggeredTallySyncBtn(false);
    toggleAllRowsSelected(false);
    setLoadConfigOpen(false);
  }

  function handleSyncSubmitWithAlert() {
    const ids = Object.entries(selectedRowIds).map((k) => k[0]);
    let warningMsg = `Do you want to sync All ${tab} to ${
      syncTrigger.current === "Deskera" ? "Deskera" : "Tally"
    }?`;
    if (ids && ids.length > 0) {
      warningMsg = `Do you want to sync selected ${tab}(s) to ${
        syncTrigger.current === "Deskera" ? "Deskera" : "Tally"
      } `;
    }
    showAlert("Alert", warningMsg, [
      {
        title: "Continue",
        className: "bg-blue mr-r text-white",
        onClick: () => {
          handleSyncSubmit();
        },
      },
      {
        title: "Cancel",
        className: "bg-gray text-white",
        onClick: () => {},
      },
    ]);
  }

  function getDateRange(financialDates) {
    let endDate = "";
    let startDate = "";
    if (!financialDates?.startDate || !financialDates.endDate) {
      return { startDate, endDate };
    }
    var sd = financialDates.startDate;
    var startMonth = sd.getMonth() + 1;
    var ed = financialDates.endDate
      ? financialDates.endDate
      : financialDates.startDate;
    var endMonth = ed.getMonth() + 1;
    endDate =
      ed.getUTCFullYear() +
      "-" +
      String(endMonth).padStart(2, "0") +
      "-" +
      String(ed.getDate()).padStart(2, "0");
    startDate =
      sd.getUTCFullYear() +
      "-" +
      String(startMonth).padStart(2, "0") +
      "-" +
      String(sd.getDate()).padStart(2, "0");
    return { startDate, endDate };
  }

  function getFinancialStartEndDate() {
    let bookBeginningStartDate = tenantInfo?.bookBeginningStartDate
      ? new Date(tenantInfo?.bookBeginningStartDate)
      : new Date();
    let startDate = new Date().setDate(new Date().getDate() - 7);
    startDate =
      startDate >= bookBeginningStartDate ? startDate : bookBeginningStartDate;
    const endDate = new Date();
    startDate = new Date(startDate);
    return { startDate, endDate };
  }

  function Warning() {
    return (
      <div className='bg-chip-blue border-radius-l p-h-s ml-s p-v-xs'>
        Syncing...
      </div>
    );
  }

  const setDateRangeForFilter = (financialDates) => {
    if (!financialDates) {
      financialDates = getFinancialStartEndDate();
    }

    setFilterDates({
      startDate: financialDates.startDate,
      endDate: financialDates.endDate,
    });

    let { startDate, endDate } = getDateRange(financialDates);
    refresh({ pageIndex, pageSize, status, startDate, endDate, search });
  };

  const getDateRangePicker = () => {
    return (
      <div className='position-absolute shadow-m bg-white z-10 top-12 right-20 deskera-datepicker'>
        <DKDateRangePicker
          className='w-500'
          onClose={() => {
            setShowHideCalendar(false);
          }}
          color={"rgb(33, 107, 165)"}
          startDate={filterDates.startDate}
          selectedStartDate={filterDates.startDate}
          selectedEndDate={filterDates.endDate}
          onSelectDateRange={(startDate, endDate) => {
            if (startDate && endDate) {
              setDateRangeForFilter({
                startDate: startDate,
                endDate: endDate,
              });
              setShowHideCalendar(false);
            }
          }}
        />
      </div>
    );
  };

  const getDateRangeString = () => {
    if (filterDates && filterDates.startDate) {
      return (
        getDateAsString(filterDates.startDate, "dd-MM-yyyy") +
        " to " +
        getDateAsString(filterDates.endDate, "dd-MM-yyyy")
      );
    } else {
      return "";
    }
  };
  console.log("syncTrigger.current");
  return (
    <>
      <div className='d-flex flex-row mb-3 justify-content-between'>
        <div style={{ width: "60%" }}>
          {
            <button
              className='btn p-v-s text-white bg-success border-radius-m'
              onClick={() => {
                refresh({ pageIndex, pageSize, status });
              }}
            >
              <span className='svg-icon'>
                <ArrowClockwise />
              </span>
            </button>
          }
          {
            <button
              className='btn border-radius-m p-v-s text-white bg-success ml-s'
              onClick={() => {
                syncTrigger.current = "Deskera";
                handleSyncSubmitWithAlert();
              }}
              disabled={triggered}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <span className='svg-icon svg-baseline mr-s svg-white'>
                {triggered ? (
                  <div
                    class='spinner-border text-white spinner-border-sm'
                    role='status'
                  ></div>
                ) : (
                  <></>
                )}
              </span>
              {Object.entries(selectedRowIds).length > 0 ? (
                <span>Sync Selected {tab} to Deskera</span>
              ) : (
                <span>Sync {tab} to Deskera</span>
              )}
            </button>
          }

          {
            <button
              className='btn border-radius-m p-v-s text-white bg-success ml-s'
              onClick={() => {
                syncTrigger.current = "Tally";
                handleSyncSubmitWithAlert();
              }}
              disabled={triggeredTallySyncBtn}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <span className='svg-icon svg-baseline mr-2 svg-white'>
                {triggeredTallySyncBtn ? (
                  <div
                    class='spinner-border text-white spinner-border-sm'
                    role='status'
                  ></div>
                ) : (
                  <></>
                )}
              </span>
              {Object.entries(selectedRowIds).length > 0 ? (
                <span>Sync Selected {tab} to Tally</span>
              ) : (
                <span>Sync {tab} to Tally</span>
              )}
            </button>
          }
          {(tab === "Bills" || tab === "Invoices") && (
            <button
              className='border-radius-m text-wrap-none p-v-s dk-button-hover border-m bg-white position-relative px-2 ml-s'
              onClick={() => setShowHideCalendar(true)}
              style={{ height: "38px" }}
            >
              <img
                src={ic_calendar}
                alt=''
                className='ic-r ic-s'
                style={{
                  objectFit: "scale-down",
                  marginBottom: "2px",
                }}
              />
              <span
                style={{
                  fontSize: "13px",
                  color: "rgb(45, 43, 37)",
                  marginLeft: "8px",
                }}
              >
                {getDateRangeString()}
              </span>
            </button>
          )}
          <div className='parent-width row position-relative'>
            <div
              className='position-absolute z-index-1'
              style={{ right: 350, top: 0 }}
            >
              {showHideCalendar &&
                (tab === "Bills" || tab === "Invoices") &&
                getDateRangePicker()}
            </div>
          </div>
        </div>
        <div style={{ verticalAlign: "bottom", marginTop: "5px" }}>
          {triggered && hover && Warning()}
        </div>
        <div
          className='row justify-content-end'
          style={{ width: "40%", marginRight: "2px" }}
        >
          <form className='form-inline'>
            <SearchFilter
              style={{
                display: "flex",
                justifyContent: "end",
                width: "30%",
              }}
              onFilterMap={onFilter}
            >
              <SearchBar
                placeholder={`Search ${Utility.convertInTitleCase(tab)}`}
                inputStyle={{ width: "12rem" }}
                style={{ minWidth: "15rem", margin: "2px" }}
                queryKey={"search"}
              />
            </SearchFilter>
            <Select
              placeholder='Filter By Sync Status'
              className=''
              styles={customStyles}
              options={tallyFilterStatusOption}
              // value={statusOptions.filter((option) => option.value === status)}
              menuPlacement='auto'
              onChange={(e) => {
                updateSelectedStatus(e);
                console.log("status value  : ", status);
                console.log("select value  : ", e);
              }}
              theme={customSelectTheme}
              components={{
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
              isMulti={true}
            />
          </form>
        </div>
      </div>
      <div
        className='card'
        style={{
          overflowX: "auto",
          height: "100%",
        }}
      >
        <table
          style={{ minHeight: minHeight ?? "auto" }}
          className='table m-0 dk-table-hover dk-table'
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      { className: column.className },
                      column.getSortByToggleProps(),
                    ])}
                    style={{
                      whiteSpace: "nowrap",
                      width: `${column.width ?? "auto"}`,
                    }}
                  >
                    <span className=' fs-r text-align-left fw-m fs-m text-gray cursor-hand '>
                      {column.render("Header")}
                    </span>
                    <span
                      className={clsx(
                        "svg-icon svg-disabled svg-baseline",
                        !column.isSorted && "invisible",
                        column.isSorted && column.isSortedDesc && "svg-flipped"
                      )}
                    >
                      <UpArrowIcon />
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!loading && dataLength === 0 && (
              <tr>
                <td colSpan={colummsLength}>
                  <div className='d-flex flex-column align-items-center'>
                    <span
                      className='mt-3 svg-disabled'
                      style={{ width: "50px", height: "50px" }}
                    >
                      <EmptyTray />
                    </span>
                    <span className='mt-2 text-muted'>
                      No records were found.
                    </span>
                  </div>
                </td>
              </tr>
            )}
            {!loading &&
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps([
                          { className: cell.column.className },
                        ])}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
          {loading && (
            <tr>
              <td colSpan={colummsLength}>
                <div className='d-flex flex-column align-items-center'>
                  <span className='mt-2 text-muted'>Loading...</span>
                </div>
              </td>
            </tr>
          )}
        </table>
      </div>

      <div className='d-flex flex-row-reverse align-items-center mt-3'>
        <div className='d-flex flex-row align-items-center'>
          <span className='mr-2'>Max rows per page:</span>
          <Select
            placeholder='Page'
            className='page-selector'
            options={pageSizeOptions}
            value={pageSizeOptions.filter(
              (option) => option.value === pageSize
            )}
            menuPlacement='auto'
            onChange={(e) => {
              setPageSize(Number(e.value));
            }}
            theme={customSelectTheme}
            components={{
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
          />
        </div>
        <div className='d-flex flex-row align-items-center mr-2'>
          <button
            className='btn mr-2'
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ForwardRight />
            </span>
          </button>
          <button
            className='btn mr-2'
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ArrowRight />
            </span>
          </button>
          <span className='mr-2'>
            {pageIndex + 1} / {Math.max(pageOptions.length, 1)}
          </span>
          <button
            className='btn mr-2'
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <span
              className={clsx(
                "svg-icon svg-baseline",
                canNextPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ArrowRight />
            </span>
          </button>
          <button
            className='btn'
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <span
              className={clsx(
                "svg-icon svg-baseline",
                canNextPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ForwardRight />
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default ServerPaginationTable;
