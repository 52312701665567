import ReactTooltip from "react-tooltip";

const SyncRemarkRender = ({ value, id }) => {
  return (
    <div>
      <span data-tip='React-tooltip' data-for={id}>
        {value.length >= 30 ? value.substring(0, 30) + "..." : value}
      </span>
      {value.length >= 30 && (
        <ReactTooltip
          className='bg-danger'
          place='top'
          type='error'
          id={id}
          effect='solid'
        >
          <span>{value ? value : "An error occurred."}</span>
        </ReactTooltip>
      )}
    </div>
  );
};

export default SyncRemarkRender;
