import { CREATE_TOAST, DELETE_TOAST, MARK_TOAST_DELETED } from "../types";
import shortid from "shortid";

export function createToast(toast, id) {
  return {
    type: CREATE_TOAST,
    toast,
    id,
  };
}

export function markToastDeleted(id) {
  return {
    type: MARK_TOAST_DELETED,
    id,
  };
}

export function deleteToast(id) {
  return {
    type: DELETE_TOAST,
    id,
  };
}

export function addToast(toast) {
  const id = shortid.generate();
  return (dispatch) => {
    dispatch(createToast(toast, id));
    setTimeout(() => dispatch(markToastDeleted(id)), 2500);
  };
}
