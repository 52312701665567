import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../components/Alert";
import { Link } from "react-router-dom";
import { loadAccounts, loadDeliveryNotes } from "../../redux/actions/tally";
import ServerPaginationTable from "../../components/ServerPaginationTable";
import SyncStatus from "../../components/SyncStatus";
import {
  migrateDeliveryNotesData,
  migrateDeliveryNoteToTally,
} from "../../api/index";
import SyncRemarkRender from "../../components/SyncRemarkRender";

const columns = [
  {
    Header: "Voucher ID",
    accessor: "cartNoteId",
  },
  {
    Header: "Voucher Number",
    accessor: "cartNoteSequenceCode",
  },
  {
    Header: "Voucher Date",
    accessor: "noteDate",
  },
  {
    Header: "Deskera ID",
    accessor: "deskeraId",
  },
  {
    Header: "Deskera Sequence Code",
    accessor: "deskeraDocumentSequenceCode",
  },
  {
    Header: "Sync Status",
    accessor: "syncStatus",
    Cell: ({ cell: { row } }) => {
      return (
        <SyncStatus
          status={row.original.syncStatus}
          reason={row.original.reason}
          id={row.original.cartProductId}
        />
      );
    },
  },
  {
    Header: "Sync Remarks",
    accessor: "reason",
    disableSortBy: true,
    Cell: ({ cell: { row } }) => {
      let value =
        row.original?.syncStatus !== "SUCCESSFUL" && row.original?.reason
          ? row.original?.reason
          : "";
      return <SyncRemarkRender value={value} id={row.original.id.toString()} />;
    },
  },
];

function DeliveryNotes() {
  const tallyConfig = useSelector((state) => state.tally.accounts);
  const deliveryNotesData = useSelector((state) => {
    return state.tally.deliveryNotes;
  });
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(loadAccounts());
    dispatch(loadDeliveryNotes());
  }, []);

  const data = useMemo(() => deliveryNotesData.data, [deliveryNotesData]);

  useEffect(() => {
    setLoading(false);
    if (deliveryNotesData && deliveryNotesData?.data?.totalPages) {
      setPageCount(deliveryNotesData?.data?.totalPages);
    }
  }, [deliveryNotesData]);

  const refreshData = ({ pageSize, pageIndex, status, sortBy, search }) => {
    setLoading(true);
    dispatch(
      loadDeliveryNotes(pageSize, pageIndex, status, sortBy, search ?? "")
    );
  };

  return (
    <>
      <div className='p-4'>
        <h3 className='mb-3'>Delivery Notes</h3>
        {!tallyConfig.data ||
          (!tallyConfig.data.syncEnable && (
            <Alert type='warning'>
              Your synced Delivery Notes Vouchers will appear here. Please
              complete{" "}
              <Link to='/app/tally' className='text-alert-warning'>
                <u>Setup</u>
              </Link>{" "}
              before continuing.
            </Alert>
          ))}
        <ServerPaginationTable
          data={data.content ? data.content : []}
          columns={columns}
          migrate={migrateDeliveryNotesData}
          syncToTally={migrateDeliveryNoteToTally}
          cart='tally'
          refresh={refreshData}
          tab='Delivery Notes'
          pageCount={pageCount}
          loading={loading}
          rowId='id'
        />
      </div>
    </>
  );
}

export default DeliveryNotes;
