import axios from "axios";

const API = axios.create({
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
  },
});

API.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // check whether request was a network failure, or unauthenticated or failed to login status
    if (
      !originalRequest._retry &&
      (!error.response ||
        error.response.status === 401 ||
        originalRequest.url.includes("/login/status"))
    ) {
      // if its refreshtoken request that failed, do not retry
      if (originalRequest.url.includes("getrefreshtoken")) {
        return Promise.reject(error);
      }

      originalRequest._retry = true;
      try {
        await refreshAccessToken();
      } catch {
        return Promise.reject(error);
      }

      return API(originalRequest);
    }
    throw error;
  }
);

export function setAuthToken(token) {
  API.defaults.headers["x-access-token"] = token;
}

export async function getLoginStatus() {
  try {
    const resp = await API.get(process.env.REACT_APP_LOGIN_STATUS_URL);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTenantInfo() {
  try {
    const resp = await API.get(process.env.REACT_APP_TENANT_URL);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraUserInfo() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/deskera/user"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraLoginUserInfo(userId) {
  try {
    const resp = await API.get(
      process.env.REACT_APP_BASE_URL + "v1/users/" + userId
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraRedirectUrl() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/deskera/redirectUrl?app=tally"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setDeskeraConnection(connectionInfo) {
  let connectionUrl = new URL(
    process.env.REACT_APP_SERVER_URL + "/deskera/oauth?app=tally"
  );

  connectionUrl.searchParams.append("code", connectionInfo.code);
  connectionUrl.searchParams.append("state", connectionInfo.state);

  try {
    const resp = await API.get(connectionUrl.toString());
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedDeskeraAccount() {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/deskera/disconnect"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function refreshAccessToken() {
  const requestBody = {
    refreshToken: localStorage.getItem("deskera-refresh-token"),
  };
  try {
    const resp = await API.post(
      process.env.REACT_APP_TOKEN_REFRESH_URL,
      requestBody
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getLogout() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_BASE_URL + "v1/iam/auth/logout2"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraBooksAccounts() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/deskera/books/accounts"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraBooksWarehouses() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/deskera/books/warehouses"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraProductUom() {
  try {
    const resp = await API.get(process.env.REACT_APP_UOM_URL);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraBooksTaxes() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/deskera/books/taxes"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraTenantDetails() {
  try {
    const resp = await API.get(process.env.REACT_APP_TENANT_URL);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function uploadInventoryFile(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_BASE_URL + "v1/attachment/file-upload",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getCartConfig(cartType) {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/cart/" + cartType + "/config"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

/* Tally apis */
export async function getTallyConnection() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/tally/connection"
    );
    return resp.data;
  } catch (err) {
    console.error(err);
    return {};
  }
}

export async function saveTallyConnection(payload) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/tally/connection",
      payload
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function disconnectTallyCompany() {
  try {
    const resp = await API.delete(
      process.env.REACT_APP_SERVER_URL + "/tally/connection"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setTallyConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyConfig() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/config"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyCreditNotes(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/credit-note?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyCreditNotesData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/credit-note",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateCNToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/tally/save-tally-creditnote-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyDebitNotes(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/debit-note?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyDebitNotesData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/debit-note",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateDebitNoteToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/tally/save-tally-debitnote-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyJournalVoucher(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/journal?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyJournalData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/journal-entry",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateJEToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/tally/save-tally-journal-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyProducts(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/products?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyProductsData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateProductsToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/tally/save-tally-product-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyCustomers(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/customers?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyCustomersData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/customers",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateCustomersToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/tally/save-tally-customer-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyInvoices(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search,
  fromDate = "",
  toDate = ""
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/invoices?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyInvoicesData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/invoices",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateInvoicesToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/tally/save-tally-invoice-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyBills(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  fromDate = "",
  toDate = "",
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/bills?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyBillsData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/bills",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateBillsToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "tally/save-tally-bill-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyPayments(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/payments?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyPaymentsData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/payments",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migratePaymentsToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/tally/save-tally-payment-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyReceipts(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/receipts?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyReceiptsData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/receipts",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateReceiptsToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/tally/save-tally-receipt-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyPurchaseOrders(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/purchaseOrders?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyPurchaseOrdersData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/purchaseOrders",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migratePOToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL +
        "/tally/save-tally-purchase-order-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallySalesOrders(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/salesOrders?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallySalesOrdersData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/salesOrders",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateSoToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/tally/save-tally-sales-order-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyGRN(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/goodsreceipts?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyGRNData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/goodsreceipts",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateGRNToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL +
        "/tally/save-tally-goods-receipts-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyDeliveryNotes(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/deliverynotes?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateDeliveryNotesData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/deliverynotes",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateDeliveryNoteToTally(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL +
        "/tally/save-tally-delivery-notes-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}
