import TallyLogo from "../assets/Tally";

function SplashScreen({ style, className }) {
  const data = {
    logo: <TallyLogo />,
  };

  return (
    <div
      className={
        "d-flex flex-column align-items-center justify-content-center bg-white vw-100 vh-100 " +
        className
      }
      style={{
        zIndex: 100,
        position: "absolute",
        top: 0,
        right: 0,
        ...style,
      }}
    >
      <h1 className='mb-3 display-3 svg-icon svg-baseline'>{data.logo}</h1>
      <h4 className='text-wine'>Deskera Tally Integration</h4>
      <div
        class='spinner-border text-secondary spinner-border-sm'
        role='status'
      >
        <span class='sr-only text-muted'>Loading...</span>
      </div>
    </div>
  );
}

export default SplashScreen;
