import { SET_CURRENT_USER, SET_DESKERA_INFO, SET_USER_INFO } from "../types";

export function setCurrentUser(user, syncEnabled) {
  return {
    type: SET_CURRENT_USER,
    user: user,
    syncEnabled: syncEnabled,
  };
}

export function setDeskeraInfo(deskeraInfo) {
  return {
    type: SET_DESKERA_INFO,
    info: deskeraInfo,
  };
}
export function setUserInfo(userInfo) {
  return {
    type: SET_USER_INFO,
    info: userInfo,
  };
}
