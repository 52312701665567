import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { deleteToast } from "./../redux/actions/toasts";
import { CSSTransition } from "react-transition-group";

const styleMap = {
  success: ["border-success", "bg-success"],
  danger: ["border-danger", "bg-danger"],
};

function ToastList() {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.toasts);
  const messages = toasts.map((toast) => (
    <CSSTransition
      in={toast.active}
      appear={true}
      classNames='scale-opacity'
      timeout={300}
      unmountOnExit
      onExited={() => dispatch(deleteToast(toast.id))}
    >
      <div
        class={clsx(
          // "alert",
          "dk-shadow-sm",
          "d-flex",
          "flex-row",
          "justify-content-between",
          "border-bottom",
          "text-light",
          "text-center",
          ...styleMap[toast.type]
        )}
        style={{
          marginBottom: 1,
          marginTop: "5%",
          borderRadius: 5,
          padding: 10,
          right: "42%",
          top: "0px",
          position: "fixed",
        }}
      >
        <p className='m-0'>{toast.message}</p>
      </div>
    </CSSTransition>
  ));
  return (
    <div class='toast-list' style={{ zIndex: 100 }}>
      {messages}
    </div>
  );
}

export default ToastList;
