import Select from "react-select";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import {
  getDeskeraRedirectUrl,
  getDeskeraUserInfo,
  saveTallyConnection,
  setDisconnectedDeskeraAccount,
  getDeskeraBooksWarehouses,
  getDeskeraBooksAccounts,
  getDeskeraProductUom,
  getDeskeraBooksTaxes,
  setTallyConfig,
  disconnectTallyCompany,
} from "../../api/index";
import CheckIcon from "../../assets/Check";
import CloseIcon from "../../assets/Close";
import Copy from "../../assets/Copy";
import DeleteLogo from "../../assets/Delete";
import IntegrationFile from "../../assets/Deskera-Tally-Integration.tcp";
import Download from "../../assets/Download";
import FloppyDiskIcon from "../../assets/FloppyDisk";
import PlusLogo from "../../assets/Plus";
import SyncIcon from "../../assets/Sync";

import SyncDisableIcon from "../../assets/SyncDisable";
import Alert from "../../components/Alert";
import { setDeskeraInfo } from "../../redux/actions/auth";
import { loadAccounts, loadConfig, setConfig } from "../../redux/actions/tally";
import { addToast } from "../../redux/actions/toasts";
import customSelectTheme from "../../utils/selectTheme";
import { Utility } from "../../utils/Utility";

function Config() {
  const [disconnectWarning, setDisconnectWarning] = useState(false);
  const [syncWarning, setSyncWarning] = useState(false);
  const [syncButton, setSyncButton] = useState(true);
  const [disconnectButton, setDisconnectButton] = useState(true);
  const [generateToken, setGenerateToken] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const tallyConfig = useSelector((state) => state.tally.accounts);
  const [saveConnectionLoading, setSaveConnectionLoading] = useState(false);
  const [saveSyncLoading, setSaveSyncLoading] = useState(false);
  const [deskeraAccounts, setDeskeraAccounts] = useState([]);
  const [deskeraWarehouses, setDeskeraWarehouses] = useState([]);
  const [deskeraTaxes, setDeskeraTaxes] = useState([]);
  const useDocumentCodeFromOptions = [
    { value: true, label: "YES" },
    { value: false, label: "NO" },
  ];
  const [defaultDeskeraWarehouses, setDefaultDeskeraWarehouses] = useState([]);
  const [deskeraProductUom, setDeskeraProductUom] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const config = useSelector((state) => state.tally.config);
  const [accountsConfigured, setAccountsConfigured] = useState(false);
  const [validAccounts, setValidAccounts] = useState(false);
  const [saveConfigLoading, setSaveConfigLoading] = useState(false);
  const [configOpen, setConfigOpen] = useState(false);
  const [disconnectWarningShop, setDisconnectWarningShop] = useState(false);
  const [disconnectButtonShop, setDisconnectButtonShop] = useState(true);
  const [tallyKeysInfo, setTallyKeysInfo] = useState({});
  const [tallyKeysFormErrors, setTallyKeysFormErrors] = useState({});
  const [apiKeysError, setApiKeysError] = useState(false);
  const [saveShopLoading, setSaveShopLoading] = useState(false);

  const configAccounts = [
    {
      name: "Bank Account",
      accessor: "bankAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Purchase Account",
      accessor: "productPurchaseAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Purchase Return Account",
      accessor: "productPurchaseReturnAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Sales Account",
      accessor: "productSalesAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Sales Return Account",
      accessor: "productSalesReturnAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Payable Account",
      accessor: "contactPayableAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Receivable Account",
      accessor: "contactReceivableAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Cost of Goods Sold Account",
      accessor: "productCostOfGoodsSoldAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Inventory Account",
      accessor: "productInventoryAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Stock Adjustment Account",
      accessor: "productStockAdjustmentAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Product Unit of Measure",
      accessor: "productUom",
      options: deskeraProductUom,
    },
    {
      name: "Product Purchase Tax",
      accessor: "productPurchaseTaxCode",
      options: deskeraTaxes,
    },
    {
      name: "Product Sales Tax",
      accessor: "productSalesTaxCode",
      options: deskeraTaxes,
    },
    {
      name: "Product Warehouse",
      accessor: "productWarehouseCode",
      options: defaultDeskeraWarehouses.filter(
        (wh) => wh.value !== "NO_SPECIFIC_WH"
      ),
    },
    {
      name: "Use Document Code from Tally for Invoice",
      accessor: "useTallySequenceCodeForInvoice",
      options: useDocumentCodeFromOptions,
    },

    {
      name: "Use Document Code from Tally for Bill",
      accessor: "useTallySequenceCodeForBill",
      options: useDocumentCodeFromOptions,
    },

    {
      name: "Use Document Code from Tally for Journal",
      accessor: "useTallySequenceCodeForJournal",
      options: useDocumentCodeFromOptions,
    },

    {
      name: "Use Document Code from Tally for Credit Note",
      accessor: "useTallySequenceCodeForCreditNote",
      options: useDocumentCodeFromOptions,
    },
    {
      name: "Use Document Code from Tally for Debit Note",
      accessor: "useTallySequenceCodeForDebitNote",
      options: useDocumentCodeFromOptions,
    },
    {
      name: "Use Document Code from Tally for Product",
      accessor: "useTallySequenceCodeForProduct",
      options: useDocumentCodeFromOptions,
    },
    {
      name: "Use Document Code from Tally for Customer",
      accessor: "useTallySequenceCodeForCustomer",
      options: useDocumentCodeFromOptions,
    },
  ];

  useEffect(() => {
    dispatch(loadAccounts());
    dispatch(loadConfig());
    getBooksAccounts();
    getBooksWarehouses();
    getBooksProductUom();
    getBooksTaxes();
  }, []);

  async function handleAddDeskeraAccount(e) {
    e.preventDefault();
    try {
      const deskeraConnectUrl = await getDeskeraRedirectUrl();
      window.open(deskeraConnectUrl.url, "_self");
    } catch (error) {
      setError("Failed to fetch deskera url");
    }
  }

  async function handleDisconnectDeskeraAccount(e) {
    e.preventDefault();
    try {
      await setDisconnectedDeskeraAccount();
      const userInfo = await getDeskeraUserInfo();
      dispatch(setDeskeraInfo(userInfo));
    } catch (error) {
      setError("Failed to disconnect Deskera account");
    }
  }

  async function handleUpdateSync(e, syncStatus) {
    e.preventDefault();
    try {
      let payload = {
        tallyCompanyName: tallyConfig?.data?.tallyCompanyName || null,
        syncEnable: syncStatus,
        disableSyncReason: syncStatus ? "" : tallyConfig.data.disableSyncReason,
        companyGuid: tallyConfig?.data?.companyGuid
          ? tallyConfig?.data?.companyGuid
          : "",
        companyMasterId: tallyConfig?.data?.companyMasterId
          ? tallyConfig?.data?.companyMasterId
          : "",
      };

      setSaveSyncLoading(true);
      const config = await saveTallyConnection(payload);
      if (config?.deskeraAccessToken) {
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: config.syncEnable
              ? "Sync has been Enabled."
              : "Sync has been disabled.",
          })
        );
        setSaveSyncLoading(false);
      }
      dispatch(loadAccounts());
      setSyncButton(true);
      setSyncWarning(false);
    } catch {
      setError("Failed to update config");
      setSaveSyncLoading(false);
    }
  }

  async function getBooksAccounts() {
    try {
      const accounts = await getDeskeraBooksAccounts();
      const accountsOptions = [];
      accounts.forEach((account) => {
        accountsOptions.push({ value: account.code, label: account.name });
      });
      setDeskeraAccounts(accountsOptions);
    } catch {
      setError("Failed to load Deskera Books accounts");
    }
  }

  async function getBooksWarehouses() {
    try {
      const warehouses = await getDeskeraBooksWarehouses();
      const warehouseOptions = [];
      warehouses.forEach((warehouse) => {
        warehouseOptions.push({ value: warehouse.code, label: warehouse.name });
      });
      setDeskeraWarehouses(warehouseOptions);
      const tempwh = warehouseOptions;
      tempwh.push({ value: "NO_SPECIFIC_WH", label: "No Specific WareHouse" });
      setDefaultDeskeraWarehouses(tempwh);
    } catch {
      setError("Failed to load Deskera Books warehouses");
    }
  }

  async function getBooksProductUom() {
    try {
      const productUom = await getDeskeraProductUom();
      const productUomOptions = [];
      productUom.forEach((uom) => {
        productUomOptions.push({ value: uom.id, label: uom.name });
      });
      setDeskeraProductUom(productUomOptions);
    } catch {
      setError("Failed to load Deskera Books products uom");
    }
  }

  async function getBooksTaxes() {
    try {
      const taxes = await getDeskeraBooksTaxes();
      const taxesOptions = [];
      taxes.forEach((tax) => {
        taxesOptions.push({ value: tax.code, label: tax.name });
      });
      setDeskeraTaxes(taxesOptions);
    } catch {
      setError("Failed to load Deskera Books Taxes");
    }
  }

  function connectModal() {
    return (
      <div className='card-body'>
        <p className='text-muted'>Connect your Deskera account.</p>
        <div className='d-flex flex-row-reverse'>
          <button
            className='btn border-radius-m p-v-s text-white bg-success'
            onClick={handleAddDeskeraAccount}
          >
            <span className='svg-icon svg-baseline mr-2 svg-white'>
              <PlusLogo />
            </span>
            <span>Connect</span>
          </button>
        </div>
      </div>
    );
  }

  function connectedModal() {
    return (
      <div className='card-body'>
        <p className='text-muted'>Your Deskera Account is Connected</p>
        <div className='d-flex flex-row-reverse'>
          {disconnectButton && (
            <button
              onClick={(e) => setDisconnectWarning(true)}
              className='btn border-radius-m p-v-s text-white bg-danger'
            >
              <span className='svg-icon svg-baseline mr-2 svg-white'>
                <DeleteLogo />
              </span>
              <span>Disconnect</span>
            </button>
          )}
          <CSSTransition
            in={disconnectWarning}
            timeout={100}
            classNames='scale-opacity'
            unmountOnExit
            onEnter={() => setDisconnectButton(false)}
            onExited={() => setDisconnectButton(true)}
          >
            <Alert className='m-0 flex-fill' type='warning'>
              <p className='m-0'>Disconnecting will delete data. Continue?</p>
              <div className='d-flex flex-row-reverse'>
                <button
                  className='btn dk-btn font-weight-bold text-muted px-3'
                  onClick={handleDisconnectDeskeraAccount}
                >
                  <span className='svg-icon svg-baseline svg-warning'>
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className='btn dk-btn mr-2 px-3'
                  onClick={(e) => setDisconnectWarning(false)}
                >
                  <span className='svg-icon svg-baseline svg-warning'>
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function connectModalShop() {
    return (
      <div className='card w-600 mb-4 dk-card dk-card-shadow'>
        <div className='card-body'>
          <p className='text-muted'>
            To sync data from Tally to Deskera and vice-versa, please follow
            below steps
            <ol>
              <li>
                Download Tally TDL file required to sync data from Tally to
                Deskera
              </li>
              <li>
                Update TDL configuration in your Tally Prime Applicaton to load
                TDLs files on startup (Tally Help -&gt; TDL & Add ons -&gt;
                Manage Local TDLs)
              </li>
              <li>
                Restart Tally Application and look for Menu 'Sync to Deskera'
                under 'Gateway Of Tally'
              </li>
              <li>
                Contact Deskera Care at <b>care@deskera.com</b> to request your
                API Key/Token
              </li>
            </ol>
          </p>
          <div className='d-flex flex-row-reverse mb-2'>
            <button
              className='btn border-radius-m p-v-s text-white bg-info mr-2'
              onClick={(e) => handleDownloadPlugin(e)}
              disabled={saveConnectionLoading}
            >
              <span className='svg-baseline mr-2 svg-white'>
                <Download />
              </span>
              <span>Download Tally Plugin</span>
            </button>

            {/* <button
              className='btn border-radius-m p-v-s text-white bg-success mr-2'
              onClick={saveConnection}
              disabled={saveConnectionLoading}
            >
              <span className='svg-baseline mr-2 svg-disabled svg-white'>
                {saveConnectionLoading ? (
                  <div
                    class='spinner-border text-success spinner-border-sm'
                    role='status'
                  ></div>
                ) : (
                  <Copy />
                )}
              </span>
              <span>Generate Token</span>
            </button> */}
          </div>
          {generateToken && (
            <div className='input-group mb-3'>
              <input
                type='text'
                className='form-control'
                disabled={true}
                value={tallyConfig?.data?.deskeraAccessToken}
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
              <span
                className='input-group-text text-muted svg-disabled cursor-hand'
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    tallyConfig?.data?.deskeraAccessToken
                  );
                  dispatch(
                    addToast({
                      type: "success",
                      title: "Success",
                      message: "Copied access token to clipboard.",
                    })
                  );
                }}
              >
                <Copy />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  function instructionsModal() {
    return (
      <div className='card w-600 mb-4 dk-card dk-card-shadow'>
        <div className='card-body'>
          <p className='text-muted'>
            <ol>
              <li>
                Add/Update all Units of Measurement (UOM) in Deskera which are
                defined in Tally before syncing the Stock Items from Tally.
              </li>
              <li>
                Add/Update all GST Tax rates in Deskera which are defined in
                Tally and not present in Deskera before syncing the Product
              </li>
              <li>
                Create all Chart Of Account (COA) in Deskera which are present
                in the tally before syncing any vouchers
              </li>
            </ol>
          </p>
        </div>
      </div>
    );
  }

  function syncEnabledModal() {
    return (
      <div className='card-body'>
        <p className='text-muted'>
          Sync is enabled for your account. Orders, Products and Customers from
          your Tally will be synced with Deskera Books.
        </p>
        <div className='d-flex flex-row-reverse'>
          {syncButton && (
            <button
              className='btn border-radius-m p-v-s text-white bg-danger'
              onClick={(e) => setSyncWarning(true)}
            >
              <span className='svg-icon svg-baseline mr-2 svg-white'>
                <SyncDisableIcon />
              </span>
              <span>Disable Sync</span>
            </button>
          )}
          <CSSTransition
            in={syncWarning}
            timeout={100}
            classNames='scale-opacity'
            unmountOnExit
            onEnter={() => setSyncButton(false)}
            onExited={() => setSyncButton(true)}
          >
            <Alert type='warning' className='m-0 flex-fill'>
              <p className='m-0'>Data will no longer be synced. Continue?</p>
              <div className='d-flex flex-row-reverse'>
                <button
                  className='btn dk-btn font-weight-bold text-muted px-3'
                  onClick={(e) => handleUpdateSync(e, false)}
                >
                  <span className='svg-icon svg-baseline svg-warning'>
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className='btn dk-btn mr-2 px-3'
                  onClick={(e) => setSyncWarning(false)}
                >
                  <span className='svg-icon svg-baseline svg-warning'>
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncDisabledModal() {
    return (
      <div className='card-body'>
        <p className='text-muted'>
          Sync is Disabled for your account. Orders, Products and Customers from
          your Tally are not being synced with Deskera Books.
        </p>
        <div className='d-flex flex-row-reverse'>
          <button
            onClick={(e) => handleUpdateSync(e, true)}
            disabled={saveSyncLoading}
            className='btn border-radius-m p-v-s text-white bg-success'
          >
            <span className='svg-icon svg-baseline mr-2 svg-white'>
              <SyncIcon />
            </span>
            <span>Enable Sync</span>
          </button>
        </div>
      </div>
    );
  }

  async function handleDownloadPlugin(e) {
    e.preventDefault();

    try {
      triggerDownload(null, "Deskera-Tally-Integration.tcp", IntegrationFile);
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Failed to download tally plugin.",
        })
      );
      setError("Failed to download tally plugin.");
    }
  }

  function triggerDownload(blobContent, fileName, altSrc) {
    const downloadAnchor = document.createElement("a");
    downloadAnchor.setAttribute("download", fileName);
    /* Supporting IE */
    if (blobContent && navigator.msSaveBlob) {
      navigator.msSaveBlob(blobContent, fileName);
    } else if (downloadAnchor.download !== undefined) {
      /* Checking If download feature supported in browser */
      downloadAnchor.href = blobContent
        ? URL.createObjectURL(blobContent)
        : altSrc;
      document.body.appendChild(downloadAnchor);
      downloadAnchor.click();
      document.body.removeChild(downloadAnchor);
    }
  }

  async function saveConnection(e) {
    e.preventDefault();
    setSaveConnectionLoading(true);

    try {
      let payload = {
        tallyCompanyName: tallyConfig?.data?.tallyCompanyName || null,
        syncEnable: tallyConfig?.data?.syncEnable || false,
      };
      const config = await saveTallyConnection(payload);
      if (config?.deskeraAccessToken) {
        navigator.clipboard.writeText(config.deskeraAccessToken);
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Copied access token to clipboard.",
          })
        );
        dispatch(setConfig(config));
        setGenerateToken(true);
      }
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Failed to generate access token.",
        })
      );
      setError("Failed to generate access token.");
    }
    setSaveConnectionLoading(false);
  }

  function checkAccountsAreValid() {
    const configErrors = {};
    if (!config.data.bankAccountCode) {
      configErrors.bankAccountCode = "Select a valid account";
      setFormErrors({
        ...formErrors,
        bankAccountCode: "Select a valid account",
      });
    } //1

    if (!config.data.productPurchaseAccountCode) {
      configErrors.productPurchaseAccountCode = "Select a valid account";
      setFormErrors({
        ...formErrors,
        productPurchaseAccountCode: "Select a valid account",
      });
    } //2

    if (!config.data.productSalesAccountCode) {
      configErrors.productSalesAccountCode = "Select a valid account";
      setFormErrors({
        ...formErrors,
        productSalesAccountCode: "Select a valid account",
      });
    } //3

    if (!config.data.productSalesReturnAccountCode) {
      configErrors.productSalesReturnAccountCode = "Select a valid account";
      setFormErrors({
        ...formErrors,
        productSalesReturnAccountCode: "Select a valid account",
      });
    } //4

    if (!config.data.productPurchaseReturnAccountCode) {
      configErrors.productPurchaseReturnAccountCode = "Select a valid account";
      setFormErrors({
        ...formErrors,
        productPurchaseReturnAccountCode: "Select a valid account",
      });
    } //5

    if (!config.data.contactPayableAccountCode) {
      configErrors.contactPayableAccountCode = "Select a valid account";
      setFormErrors({
        ...formErrors,
        contactPayableAccountCode: "Select a valid account",
      });
    } //6

    if (!config.data.contactPayableAccountCode) {
      configErrors.contactPayableAccountCode = "Select a valid account";
      setFormErrors({
        ...formErrors,
        contactPayableAccountCode: "Select a valid account",
      });
    } //7

    if (!config.data.contactReceivableAccountCode) {
      configErrors.contactReceivableAccountCode = "Select a valid account";
      setFormErrors({
        ...formErrors,
        contactReceivableAccountCode: "Select a valid account",
      });
    } //8

    if (!config.data.productUom) {
      configErrors.productUom = "Select a valid account";
      setFormErrors({ ...formErrors, productUom: "Select a valid account" });
    } //9
    if (!config.data.productWarehouseCode) {
      configErrors.productWarehouseCode = "Select a valid account";
      setFormErrors({
        ...formErrors,
        productWarehouseCode: "Select a valid account",
      });
    } //10
    if (!config.data.productCostOfGoodsSoldAccountCode) {
      configErrors.productCostOfGoodsSoldAccountCode = "Select a valid account";
      setFormErrors({
        ...formErrors,
        productCostOfGoodsSoldAccountCode: "Select a valid account",
      });
    } //11
    if (!config.data.productInventoryAccountCode) {
      configErrors.productInventoryAccountCode = "Select a valid account";
      setFormErrors({
        ...formErrors,
        productInventoryAccountCode: "Select a valid account",
      });
    } //12
    if (!config.data.productStockAdjustmentAccountCode) {
      configErrors.productStockAdjustmentAccountCode = "Select a valid account";
      setFormErrors({
        ...formErrors,
        productStockAdjustmentAccountCode: "Select a valid account",
      });
    } //13
    // if (!config.data.productAction) {
    //   configErrors.productAction = "Select a valid action for products to create at Deskera";
    //   setFormErrors({ ...formErrors, productAction: "Select a valid action for products to create at Deskera" });
    // } //14

    if (!config.data.hasOwnProperty("useTallySequenceCodeForInvoice")) {
      configErrors.useTallySequenceCodeForInvoice = "Select a valid option";
      setFormErrors({
        ...formErrors,
        useTallySequenceCodeForInvoice: "Select a valid option",
      });
    } //15

    if (!config.data.hasOwnProperty("useTallySequenceCodeForBill")) {
      configErrors.useTallySequenceCodeForBill = "Select a valid option";
      setFormErrors({
        ...formErrors,
        useTallySequenceCodeForBill: "Select a valid option",
      });
    } //16

    if (!config.data.hasOwnProperty("useTallySequenceCodeForJournal")) {
      configErrors.useTallySequenceCodeForJournal = "Select a valid option";
      setFormErrors({
        ...formErrors,
        useTallySequenceCodeForJournal: "Select a valid option",
      });
    } //17

    if (!config.data.hasOwnProperty("useTallySequenceCodeForCreditNote")) {
      configErrors.useTallySequenceCodeForCreditNote = "Select a valid option";
      setFormErrors({
        ...formErrors,
        useTallySequenceCodeForCreditNote: "Select a valid option",
      });
    } //18

    if (!config.data.hasOwnProperty("useTallySequenceCodeForDebitNote")) {
      configErrors.useTallySequenceCodeForDebitNote = "Select a valid option";
      setFormErrors({
        ...formErrors,
        useTallySequenceCodeForDebitNote: "Select a valid option",
      });
    } //19

    if (!config.data.hasOwnProperty("useTallySequenceCodeForProduct")) {
      configErrors.useTallySequenceCodeForProduct = "Select a valid option";
      setFormErrors({
        ...formErrors,
        useTallySequenceCodeForProduct: "Select a valid option",
      });
    } //20
    if (!config.data.hasOwnProperty("useTallySequenceCodeForCustomer")) {
      configErrors.useTallySequenceCodeForCustomer = "Select a valid option";
      setFormErrors({
        ...formErrors,
        useTallySequenceCodeForCustomer: "Select a valid option",
      });
    } //21

    if (Object.keys(configErrors).length > 0) {
      setFormErrors(configErrors);
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Some account mappings are missing.",
        })
      );
      return false;
    } else {
      setFormErrors({});
      return true;
    }
  }

  async function saveAccountConfigs(e) {
    e.preventDefault();
    const validConfigAccounts = checkAccountsAreValid();
    if (!validConfigAccounts) {
      return;
    }
    setSaveConfigLoading(true);
    try {
      const newConfig = { ...config.data };
      const configResp = await setTallyConfig(newConfig);
      const configRes = configResp;
      dispatch(setConfig(configRes));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Books Accounts Updated successfully",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSaveConfigLoading(false);
  }

  function accountSetupModel() {
    return (
      <div className='card w-600 mb-4 dk-card dk-card-shadow'>
        <div className='card-body'>
          {!accountsConfigured && (
            <Alert type='primary'>
              Accounts must be configured before start syncing with Deskera
              Books.
            </Alert>
          )}
          {configAccounts.map((configAccount) => (
            <div className='form-group'>
              <div className='text-muted mb-2' aria='label'>
                <b>{configAccount.name}</b>
              </div>
              <Select
                placeholder='Select an Account'
                theme={customSelectTheme}
                options={configAccount.options}
                isSearchable={true}
                menuPlacement='auto'
                onChange={(e) => {
                  if (e?.value || e.label === "NO") {
                    setFormErrors({
                      ...formErrors,
                      [configAccount.accessor]: "",
                    });
                  }

                  dispatch(
                    setConfig({
                      ...config.data,
                      [configAccount.accessor]: e.value,
                    })
                  );
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                value={configAccount.options.filter(
                  (obj) => obj.value === config.data[configAccount.accessor]
                )}
              />
              {formErrors[configAccount.accessor] && (
                <div>{formErrors[configAccount.accessor]}</div>
              )}
            </div>
          ))}
          <div className='d-flex flex-row-reverse'>
            <button
              onClick={saveAccountConfigs}
              className='btn border-radius-m p-v-s text-white bg-success'
              disabled={saveConfigLoading}
            >
              <span className='svg-icon svg-baseline mr-2 svg-white'>
                {saveConfigLoading ? (
                  <div
                    class='spinner-border text-white spinner-border-sm'
                    role='status'
                  ></div>
                ) : (
                  <FloppyDiskIcon />
                )}
              </span>
              <span>Save</span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  async function handleDisconnectTallyAccount(e) {
    e.preventDefault();
    try {
      await disconnectTallyCompany();
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Tally Company disconnected successfully.",
        })
      );
      dispatch(loadAccounts());
    } catch {
      setError("Failed to disconnect Tally Company");
    }
  }

  async function disconnectTally(e) {
    await handleDisconnectTallyAccount(e);
    setDisconnectWarningShop(false);
  }

  function connectTallyModalShop() {
    return (
      <div className='card w-600 mb-4 dk-card dk-card-shadow'>
        <div className='card-body'>
          <p className='text-muted'>Link your Tally Company.</p>
          <div className='d-flex flex-row-reverse mb-2'>
            <button
              className='btn border-radius-m p-v-s text-white bg-success'
              onClick={(e) => setConfigOpen(true)}
            >
              <span className='svg-icon svg-baseline mr-2 svg-white'>
                <PlusLogo />
              </span>
              <span>
                {tallyConfig?.data?.length
                  ? "Add Another Company"
                  : "Connect Tally Company"}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  function connectedModalShop() {
    return (
      <>
        <div className='card w-600 mb-4 dk-card dk-card-shadow'>
          <div className='card-body'>
            <p className='text-muted'>Your Tally Company is linked.</p>
            <table className='table m-0 table-no-padding'>
              {!disconnectWarningShop && (
                <thead>
                  <tr>
                    <th>Company</th>
                    <th></th>
                  </tr>
                </thead>
              )}
              <tbody>
                <div>
                  {!disconnectWarningShop && (
                    <tr>
                      <td className='align-middle' style={{ width: "10%" }}>
                        <p className='m-0'>
                          <b>{tallyConfig?.data?.tallyCompanyName}</b>
                        </p>
                        <span class='d-inline-block text-truncate'>
                          <small>{tallyConfig?.data?.companyGuid}</small>
                        </span>
                      </td>
                      <td className='align-middle text-right'>
                        <button
                          onClick={(e) => setDisconnectWarningShop(true)}
                          className='btn border-radius-m p-v-s text-white bg-danger'
                        >
                          <span className='svg-icon svg-baseline mr-2 svg-white'>
                            <DeleteLogo />
                          </span>
                          <span>Disconnect</span>
                        </button>
                      </td>
                    </tr>
                  )}

                  <CSSTransition
                    in={disconnectWarningShop}
                    timeout={100}
                    classNames='scale-opacity'
                    unmountOnExit
                    onEnter={() => setDisconnectButtonShop(false)}
                    onExited={() => setDisconnectButtonShop(true)}
                  >
                    <Alert className='m-0 flex-fill' type='warning'>
                      <p className='m-0'>Disconnect Tally Company</p>
                      <div className='d-flex flex-row-reverse'>
                        <button
                          className='btn dk-btn font-weight-bold text-muted px-3'
                          onClick={(e) => disconnectTally(e)}
                        >
                          <span className='svg-icon svg-baseline svg-warning'>
                            <CheckIcon />
                          </span>
                        </button>
                        <button
                          className='btn dk-btn mr-2 px-3'
                          onClick={(e) => setDisconnectWarningShop(false)}
                        >
                          <span className='svg-icon svg-baseline svg-warning'>
                            <CloseIcon />
                          </span>
                        </button>
                      </div>
                    </Alert>
                  </CSSTransition>
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  async function handleAddTallyCompany(e) {
    e.preventDefault();
    if (!tallyKeysInfo?.tallyCompanyName) {
      setTallyKeysFormErrors({
        ...tallyKeysFormErrors,
        tallyCompanyNameError: "Enter a Company Name",
      });
    }
    if (!tallyKeysInfo?.tallyCompanyGuid) {
      setTallyKeysFormErrors({
        ...tallyKeysFormErrors,
        tallyCompanyGuidError: "Enter Tally Company Guid",
      });
    }
    if (!tallyKeysInfo?.tallyCompanyMasterId) {
      setTallyKeysFormErrors({
        ...tallyKeysFormErrors,
        tallyCompanyMasterIdError: "Master ID is required",
      });
    }

    if (
      !tallyKeysInfo?.tallyCompanyName ||
      !tallyKeysInfo?.tallyCompanyGuid ||
      !tallyKeysInfo?.tallyCompanyMasterId
    ) {
      return;
    }

    setSaveShopLoading(true);
    try {
      let tallyConnectionPayload = {
        tallyCompanyName: tallyKeysInfo?.tallyCompanyName,
        companyGuid: tallyKeysInfo?.tallyCompanyGuid,
        companyMasterId: parseInt(tallyKeysInfo?.tallyCompanyMasterId),
        deskeraAccessToken: tallyConfig?.data?.deskeraAccessToken
          ? tallyConfig?.data?.deskeraAccessToken
          : "",
        syncEnable: tallyConfig?.data?.syncEnable
          ? tallyConfig?.data?.syncEnable
          : "",
        disableSyncReason: tallyConfig?.data?.disableSyncReason
          ? tallyConfig?.data?.disableSyncReason
          : "",
      };
      await saveTallyConnection(tallyConnectionPayload);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Tally Config saved successfully.",
        })
      );
      dispatch(loadAccounts());
      setConfigOpen(false);
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.response.data,
        })
      );
      setError("Failed to add Tally Company");
      setApiKeysError(true);
    }
    setSaveShopLoading(false);
  }

  function tallyConnect() {
    return (
      <div className='d-flex'>
        <div
          className='vw-100 vh-100 d-flex justify-content-center align-items-center'
          style={{
            zIndex: 99,
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <div
            className='card dk-card dk-card-shadow mb-4 d-flex'
            style={{ width: "70%" }}
          >
            <div className='card-body d-flex'>
              <div className='card-body' width={{ width: "10%" }}>
                <div className='form-group'>
                  <div className='text-muted mb-2' aria='label'>
                    <b>Company Name</b>
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Company Name'
                    onChange={(e) => {
                      setTallyKeysInfo({
                        ...tallyKeysInfo,
                        tallyCompanyName: e.target.value,
                      });
                      setApiKeysError(false);
                      setTallyKeysFormErrors({
                        ...tallyKeysFormErrors,
                        tallyCompanyNameError: "",
                      });
                    }}
                  />
                  {tallyKeysFormErrors?.tallyCompanyNameError && (
                    <div className='text-danger '>
                      <small>
                        {tallyKeysFormErrors?.tallyCompanyNameError}
                      </small>
                    </div>
                  )}
                </div>
                <div className='form-group'>
                  <div className='text-muted mb-2' aria='label'>
                    <b>Company GUID</b>
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Guid'
                    onChange={(e) => {
                      setTallyKeysInfo({
                        ...tallyKeysInfo,
                        tallyCompanyGuid: e.target.value,
                      });
                      setApiKeysError(false);
                      setTallyKeysFormErrors({
                        ...tallyKeysFormErrors,
                        tallyCompanyGuidError: "",
                      });
                    }}
                  />
                  {tallyKeysFormErrors?.tallyCompanyGuidError && (
                    <div className='text-danger'>
                      <small>
                        {tallyKeysFormErrors?.tallyCompanyGuidError}
                      </small>
                    </div>
                  )}
                </div>
                <div className='form-group'>
                  <div className='text-muted mb-2' aria='label'>
                    <b>Company Master ID</b>
                  </div>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='Enter Tally Company Master Id'
                    onChange={(e) => {
                      setTallyKeysInfo({
                        ...tallyKeysInfo,
                        tallyCompanyMasterId: e.target.value,
                      });
                      setApiKeysError(false);
                      setTallyKeysFormErrors({
                        ...tallyKeysFormErrors,
                        tallyCompanyMasterIdError: "",
                      });
                    }}
                  />
                  {tallyKeysFormErrors?.tallyCompanyMasterIdError && (
                    <div className='text-danger '>
                      <small>
                        {tallyKeysFormErrors?.tallyCompanyMasterIdError}
                      </small>
                    </div>
                  )}
                </div>
                {apiKeysError && (
                  <div className='text-danger '>
                    <small>Incorrect Tally Info</small>
                  </div>
                )}
                <div className='d-flex flex-row-reverse'>
                  <button
                    className='btn border-radius-m p-v-s text-white bg-success'
                    onClick={(e) => handleAddTallyCompany(e)}
                    disabled={saveShopLoading}
                  >
                    <span className='svg-icon svg-baseline mr-2 svg-white'>
                      {saveShopLoading ? (
                        <div
                          class='spinner-border text-success spinner-border-sm'
                          role='status'
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                  <button
                    className='btn border-radius-m p-v-s text-white bg-danger'
                    style={{ marginRight: "2%" }}
                    onClick={(e) => {
                      setConfigOpen(false);
                      setApiKeysError(false);
                      setTallyKeysInfo({});
                    }}
                  >
                    <span className='svg-icon svg-baseline mr-2 svg-disabled svg-white'>
                      <CloseIcon />
                    </span>
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='p-4 w-auto'>
      {configOpen && tallyConnect()}
      <h3 className='mb-4 text-bold'>Account Setup</h3>
      {tallyConfig.data?.disableSyncReason && (
        <div className='mb-4'>
          <Alert type='warning'>{tallyConfig.data?.disableSyncReason}</Alert>
        </div>
      )}

      {deskeraInfo?.accountConnected && (
        <>
          <div className='border-bottom w-600 mb-2 text-muted'>
            <h5>Tally Setup</h5>
          </div>
          {deskeraInfo?.accountConnected && connectModalShop()}
        </>
      )}
      {deskeraInfo?.accountConnected && (
        <>
          <div className='border-bottom w-600 mb-2 text-muted'>
            <h5>Pre-requisite Setup Instructions</h5>
          </div>
          {deskeraInfo?.accountConnected && instructionsModal()}
        </>
      )}
      <div className='border-bottom w-600 mb-2 text-muted'>
        <h5>Deskera Account</h5>
      </div>
      <div className='card w-600 dk-card dk-card-shadow mb-4'>
        {deskeraInfo.accountConnected ? connectedModal() : connectModal()}
      </div>

      {deskeraInfo?.accountConnected && (
        <div className='border-bottom w-600 mb-2 text-muted'>
          <h5>Tally Company</h5>
        </div>
      )}
      {deskeraInfo?.accountConnected && Utility.isNotEmpty(tallyConfig?.data)
        ? connectedModalShop()
        : deskeraInfo?.accountConnected && connectTallyModalShop()}

      {deskeraInfo.accountConnected &&
        tallyConfig?.data?.deskeraAccessToken && (
          <>
            <div className='border-bottom w-600 mb-2 text-muted'>
              <h5>Books Accounts</h5>
            </div>
            {accountSetupModel()}
          </>
        )}

      {/*Data Sync Modal*/}
      {deskeraInfo.accountConnected &&
        tallyConfig?.data?.deskeraAccessToken && (
          <>
            <div className='border-bottom w-600 mb-2 text-muted'>
              <h5>Data Sync</h5>
            </div>
            <div className='card w-600 mb-4 dk-card dk-card-shadow'>
              {tallyConfig.data.syncEnable
                ? syncEnabledModal()
                : syncDisabledModal()}
            </div>
          </>
        )}
    </div>
  );
}

export default Config;
