import React, { useEffect, useState } from "react";
import { useAsyncDebounce } from "react-table";
import SearchLogo from "../../assets/Search";
import { isEmpty } from "../../utils/Utility";

export default function SearchBar(props) {
  const [value,setValue] = useState("");

  useEffect(()=>{
    if(value !== null){
      onPatchQuery(props?.queryKey,value)
    }
  },[value])

  const onChange = (e) => {
    setValue(e?.target?.value)
  }

  const onPatchQuery = useAsyncDebounce((key, value)=>{
    props?.patchQuery(key, value)
  },props?.debounceAfter || 200)

  return (
    <div style={{ ...props?.style }} className={props?.className || "input-group position-relative"}>
      <div class="input-group-prepend">
        <div class="input-group-text bg-light">
          <span className="svg-icon svg-disabled">{props?.searchIcon || <SearchLogo />}</span>
        </div>
      </div>
      <input
        style={{...props?.inputStyle}}
        type={props?.type || "text"}
        className={props?.className || "form-control"}
        value={value}
        placeholder={props?.placeholder || "Search"}
        onChange={onChange}
      />
    </div>
  );
}
