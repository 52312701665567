import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../components/Alert";
import { Link } from "react-router-dom";
import { loadAccounts } from "../../redux/actions/tally";
import ServerPaginationTable from "../../components/ServerPaginationTable";
import SyncStatus from "../../components/SyncStatus";
import {
  migrateTallyPaymentsData,
  migratePaymentsToTally,
} from "../../api/index";
import SyncRemarkRender from "../../components/SyncRemarkRender";
import { loadPayments } from "../../redux/actions/tally";

const columns = [
  {
    Header: "Voucher ID",
    accessor: "cartPaymentId",
  },
  {
    Header: "Voucher Date",
    accessor: "paymentDate",
  },
  {
    Header: "Customer Name",
    accessor: "customerName",
  },
  {
    Header: "Deskera ID",
    accessor: "deskeraId",
  },
  {
    Header: "Deskera Sequence Code",
    accessor: "deskeraDocumentCode",
  },
  {
    Header: "Sync Status",
    accessor: "syncStatus",
    Cell: ({ cell: { row } }) => {
      return (
        <SyncStatus
          status={row.original.syncStatus}
          reason={row.original.reason}
          id={row.original.cartPaymentId}
        />
      );
    },
  },
  {
    Header: "Sync Remarks",
    accessor: "reason",
    disableSortBy: true,
    Cell: ({ cell: { row } }) => {
      let value =
        row.original?.syncStatus !== "SUCCESSFUL" && row.original?.reason
          ? row.original?.reason
          : "";
      return <SyncRemarkRender value={value} id={row.original.id.toString()} />;
    },
  },
];

function Payments() {
  const paymentsData = useSelector((state) => {
    return state.tally.payments;
  });
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const tallyConfig = useSelector((state) => state.tally.accounts);

  useEffect(() => {
    dispatch(loadAccounts());
    dispatch(loadPayments());
  }, []);

  const data = useMemo(() => paymentsData.data, [paymentsData]);

  useEffect(() => {
    setLoading(false);
    if (paymentsData && paymentsData?.data?.totalPages) {
      setPageCount(paymentsData?.data?.totalPages);
    }
  }, [paymentsData]);

  const refreshData = ({ pageSize, pageIndex, status, sortBy, search }) => {
    setLoading(true);
    dispatch(loadPayments(pageSize, pageIndex, status, sortBy, search ?? ""));
  };

  return (
    <>
      <div className='p-4'>
        <h3 className='mb-3'>Payments</h3>
        {!tallyConfig.data ||
          (!tallyConfig.data.syncEnable && (
            <Alert type='warning'>
              Your synced Payments will appear here. Please complete{" "}
              <Link to='/app/tally' className='text-alert-warning'>
                <u>Setup</u>
              </Link>{" "}
              before continuing.
            </Alert>
          ))}
        <ServerPaginationTable
          data={data?.content ? data.content : []}
          columns={columns}
          migrate={migrateTallyPaymentsData}
          syncToTally={migratePaymentsToTally}
          cart='tally'
          refresh={refreshData}
          tab='Payments'
          pageCount={pageCount}
          loading={loading}
          rowId='id'
        />
      </div>
    </>
  );
}

export default Payments;
