import { useEffect } from "react";
import { useState } from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import WrenchIcon from "../../assets/Wrench";
import Logs from "../../assets/Logs";
import Config from "../../pages/Tally/Config";
import CreditNoteVoucher from "../../pages/Tally/CreditNoteVoucher";
import DebitNoteVoucher from "../../pages/Tally/DebitNoteVoucher";
import JournalVoucher from "./JournalVoucher";
import UserIcon from "../../assets/User";
import CartonBoxIcon from "../../assets/CartonBox";
import Customers from "./Customers";
import Products from "./Products";
import Invoices from "./Invoices";
import Bills from "./Bills";
import Payments from "./Payments";
import Receipts from "./Receipts";
import PurchaseOrders from "./PurchaseOrders";
import SalesOrder from "./SalesOrders";
import GoodsReceiptNotes from "./GoodsReceiptNotes";
import DeliveryNotes from "./DeliveryNotes";

function Tally() {
  const [activeVisible, setActiveVisible] = useState(false);
  const [activeTop, setActiveTop] = useState(0);

  useEffect(() => {
    sessionStorage.setItem("cartType", "tally");
  });

  const menuItems = [
    {
      name: "Setup",
      href: "/app/tally",
      icon: <WrenchIcon />,
      component: <Config />,
      exact: true,
    },
    {
      name: "Customers",
      href: "/app/tally/customers",
      icon: <UserIcon />,
      component: <Customers />,
      exact: false,
    },
    {
      name: "Products",
      href: "/app/tally/products",
      icon: <CartonBoxIcon />,
      component: <Products />,
      exact: false,
    },
    {
      name: "Purchase Order",
      href: "/app/tally/purchase-order",
      icon: <Logs />,
      component: <PurchaseOrders />,
      exact: true,
    },
    {
      name: "Sales Order",
      href: "/app/tally/sales-order",
      icon: <Logs />,
      component: <SalesOrder />,
      exact: true,
    },
    {
      name: "Invoices",
      href: "/app/tally/invoices",
      icon: <Logs />,
      component: <Invoices />,
      exact: true,
    },
    {
      name: "Bills",
      href: "/app/tally/bills",
      icon: <Logs />,
      component: <Bills />,
      exact: true,
    },
    {
      name: "Payments",
      href: "/app/tally/payments",
      icon: <Logs />,
      component: <Payments />,
      exact: true,
    },
    {
      name: "Receipts",
      href: "/app/tally/receipts",
      icon: <Logs />,
      component: <Receipts />,
      exact: true,
    },
    {
      name: "Credit Note Voucher",
      href: "/app/tally/credit-note",
      icon: <Logs />,
      component: <CreditNoteVoucher />,
      exact: true,
    },
    {
      name: "Debit Note Voucher",
      href: "/app/tally/debit-note",
      icon: <Logs />,
      component: <DebitNoteVoucher />,
      exact: true,
    },
    {
      name: "Journal Voucher",
      href: "/app/tally/journal",
      icon: <Logs />,
      component: <JournalVoucher />,
      exact: true,
    },
    {
      name: "Goods Receipt Note",
      href: "/app/tally/grn",
      icon: <Logs />,
      component: <GoodsReceiptNotes />,
      exact: true,
    },
    {
      name: "Delivery Note",
      href: "/app/tally/delivery-note",
      icon: <Logs />,
      component: <DeliveryNotes />,
      exact: true,
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          background: "#f6f6f6",
          height: "calc(100vh - 60px)",
          overflow: "hidden",
        }}
      >
        <div
          className='bg-white py-3 dk-shadow-sm hide-scroll-bar'
          style={{
            minWidth: 240,
            borderRight: "1px solid rgba(0, 0, 0, 0.1)",
            zIndex: 9,
            position: "relative",
            height: "100%",
            overflow: "auto",
          }}
        >
          {activeVisible && (
            <div
              className='ease-in-out-back'
              style={{
                width: 8,
                height: "3rem",
                position: "absolute",
                right: 0,
                top: activeTop,
                backgroundColor: "#4962df",
              }}
            ></div>
          )}
          {menuItems.map((item, index) => (
            <NavLink
              exact
              to={item.href}
              className='text-decoration-none px-4 py-3 d-block dk-navlink dk-navlink-hover mb-2 ease-in'
              isActive={(match, location) => {
                if (!match) return false;
                setActiveVisible(true);
                setActiveTop(1 + (3.375 + 0.5) * index + "rem");
                return true;
              }}
              style={{
                fontWeight: 500,
                color: "black",
              }}
            >
              <span className='svg-icon-lg svg-baseline mr-3'>{item.icon}</span>
              {item.name}
            </NavLink>
          ))}
        </div>
        <div
          className='w-100 h-100 overflow-auto'
          style={{ background: "#f6f6f6" }}
        >
          <Switch>
            {menuItems.map((item) => (
              <Route exact={item.exact} path={item.href}>
                {item.component}
              </Route>
            ))}
          </Switch>
        </div>
      </div>
    </>
  );
}

export default Tally;
