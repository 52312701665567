import { SET_CURRENT_USER, SET_DESKERA_INFO, SET_USER_INFO } from "../types";
import { isEmpty } from "lodash";

const initialState = {
  isAuthenticated: false,
  user: {},
  deskeraInfo: {},
};

function authReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.user),
        user: action.user,
      };
    case SET_DESKERA_INFO:
      return {
        ...state,
        deskeraInfo: action.info,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.info,
      };
    default:
      return state;
  }
}

export default authReducer;
