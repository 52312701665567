import { combineReducers } from "redux";
import auth from "./auth";
import tally from "./tally";
import toasts from "./toasts";

export default combineReducers({
  auth,
  tally,
  toasts,
});
