import "./App.css";
import { Redirect, Route, Switch } from "react-router-dom";
import "./index.css";
import "../src/styles/index.scss";
import LoginCheck from "./components/LoginCheck";
import NotFound from "./pages/404";
import Header from "./components/Header";
import Tally from "./pages/Tally";
import ToastList from "./components/ToastList";
import OAuth2 from "./pages/Oauth";

function App() {
  return (
    <>
      <Header />
      <ToastList />
      <Switch>
        <Route exact path='/'>
          <Redirect to='/app/tally' />
        </Route>
        <Route path='/app/tally'>
          <LoginCheck>
            <Tally />
          </LoginCheck>
        </Route>
        <Route path='/404'>
          <NotFound />
        </Route>
        <Route path='/oauth'>
          <OAuth2 />
        </Route>
      </Switch>
    </>
  );
}

export default App;
