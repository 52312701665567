
function SyncStatus({ status, reason, id }) {
  switch (status) {
    case "PARTIAL":
      return (
        <>
          <span className='badge badge-pill badge-warning' data-for={id}>
            Partial
          </span>
        </>
      );
    case "SUCCESSFUL":
      return <span className='badge badge-pill badge-success'>Success</span>;
    case "FAILED":
      return (
        <span data-for={id} className='badge badge-pill badge-danger'>
          Failed
        </span>
      );
    case "CANCELLED":
      return (
        <span data-for={id} className='badge badge-pill badge-danger'>
          Cancelled
        </span>
      );
    case "RESYNC":
      return (
          <span data-for={id} className='badge badge-pill badge-warning'>
            Resync
          </span>

      );
    case "FULFILLED":
      return <span className='badge badge-pill badge-success'>Fulfilled</span>;
    default:
      return <span className='badge badge-pill badge-info'>Pending</span>;
  }
}

export default SyncStatus;
