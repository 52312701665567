import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../components/Alert";
import { Link } from "react-router-dom";
import { loadAccounts, loadProducts } from "../../redux/actions/tally";
import ServerPaginationTable from "../../components/ServerPaginationTable";
import SyncStatus from "../../components/SyncStatus";
import {
  migrateTallyProductsData,
  migrateProductsToTally,
} from "../../api/index";
import SyncRemarkRender from "../../components/SyncRemarkRender";
import getSymbolFromCurrency from "currency-symbol-map";

const columns = [
  {
    Header: "SKU",
    accessor: "sku",
  },
  {
    Header: "Name",
    accessor: "productName",
  },
  {
    Header: "Deskera Product Code",
    accessor: "deskeraDocumentCode",
  },
  {
    Header: "Unit Price",
    accessor: "productUnitPrice",
    Cell: ({ cell: { row } }) => (
      <span>
        {row.original.productUnitPrice &&
          getSymbolFromCurrency(row.original.currency)}{" "}
        {row.original.productUnitPrice}
      </span>
    ),
    className: "text-right",
  },
  {
    Header: "Initial Quantity",
    accessor: "openingQuantity",
    className: "text-right",
  },
  {
    Header: "Sync Status",
    accessor: "syncStatus",
    Cell: ({ cell: { row } }) => {
      return (
        <SyncStatus
          status={row.original.syncStatus}
          reason={row.original.reason}
          id={row.original.cartProductId}
        />
      );
    },
  },
  {
    Header: "Sync Remarks",
    accessor: "reason",
    disableSortBy: true,
    Cell: ({ cell: { row } }) => {
      let value =
        row.original?.syncStatus !== "SUCCESSFUL" && row.original?.reason
          ? row.original?.reason
          : "";
      return <SyncRemarkRender value={value} id={row.original.id.toString()} />;
    },
  },
];

function Products() {
  const tallyConfig = useSelector((state) => state.tally.accounts);
  const productsData = useSelector((state) => {
    return state.tally.tallyProducts;
  });
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(loadAccounts());
    dispatch(loadProducts());
  }, []);

  const data = useMemo(() => productsData.data, [productsData]);

  useEffect(() => {
    setLoading(false);
    if (productsData && productsData?.data?.totalPages) {
      setPageCount(productsData?.data?.totalPages);
    }
  }, [productsData]);

  const refreshData = ({ pageSize, pageIndex, status, sortBy, search }) => {
    setLoading(true);
    dispatch(loadProducts(pageSize, pageIndex, status, sortBy, search ?? ""));
  };

  return (
    <>
      <div className='p-4'>
        <h3 className='mb-3'>Products</h3>
        {(!tallyConfig.data || !tallyConfig.data.syncEnable) && (
          <Alert type='warning'>
            Your synced Products will appear here. Please complete{" "}
            <Link to='/app/tally' className='text-alert-warning'>
              <u>Setup</u>
            </Link>{" "}
            before continuing.
          </Alert>
        )}
        <ServerPaginationTable
          data={data.content ? data.content : []}
          columns={columns}
          cart='tally'
          refresh={refreshData}
          migrate={migrateTallyProductsData}
          syncToTally={migrateProductsToTally}
          tab='Products'
          pageCount={pageCount}
          loading={loading}
          rowId='id'
        />
      </div>
    </>
  );
}

export default Products;
